<template>
  <div id="solution">
    <div class="topContent">
      <Header now-nav="/solution" ref="header"></Header>
      <div class="mainContent">
        <p data-aos="fade-down" data-aos-duration="1000">化工管廊、燃气管网、地下管网</p>
        <h4 data-aos="fade-down" data-aos-duration="1000">智慧管廊全领域专家</h4>
        <div class="contentBox">面向场景、立足需求、为用户提供定制化、软硬件结合的全栈式解决方案</div>
        <img :src="baseUrl+'imges/solution/solutionBG.png'" alt="" ref="cur" class="img_big">
        <img :src="baseUrl+'imges/solution/MobileSolutionBG.png'" ref="cur1" alt="" class="MobileBG">
      </div>
    </div>
    <div class="navContent" >
      <div class="navList"   data-aos="fade-up" data-aos-duration="500">
        <p>安全</p>
        <span>利用先进的信息科学、物联网、传感监测等技术开发为生产企业提供安全的保障</span>
        <img :src="baseUrl+'imges/solution/safety.png'" alt="">
      </div>
      <div class="navList"   data-aos="fade-up" data-aos-duration="1000">
        <p>高效</p>
        <span>利用先进的信息科学、物联网、传感监测等技术开发为生产企业提供安全的保障</span>
        <img :src="baseUrl+'imges/solution/efficient.png'" alt="">
      </div>
      <div class="navList"   data-aos="fade-up" data-aos-duration="1500">
        <p>精准</p>
        <span>利用先进的信息科学、物联网、传感监测等技术开发为生产企业提供安全的保障</span>
        <img :src="baseUrl+'imges/solution/precise.png'" alt="">
      </div>
      <div class="navList"   data-aos="fade-up" data-aos-duration="2000">
        <p>全面</p>
        <span>利用先进的信息科学、物联网、传感监测等技术开发为生产企业提供安全的保障</span>
        <img :src="baseUrl+'imges/solution/comprehensive.png'" alt="">
      </div>
    </div>
    <div class="downContent">
      <div class="downList" @click="$router.push({name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}})"   data-aos="fade-up" data-aos-duration="1000">
        <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}" class="topImg">
          <img :src="baseUrl+'imges/solution/downImg1.png'" alt="">
        </router-link>
        <div class="solutionText">
          <div class="paddingBox">
            <p class="centerText">
              <img :src="baseUrl+'home/centerImg1.png'" alt="">
              <span class="centerTitle">智慧化工管廊</span>
              <span class="centerEnglish">Smart chemical pipe gallery</span>
            </p>
            <div class="downText">
              <span>智慧化工管廊管理系统是专门针对化工园区的公共管廊，在遵循石化行业特点和特定需求的基础上，利用先进的信息...</span>
              <ul>
                <li><p>管廊、管线全流程管理</p></li>
                <li><p>应急管理数字化</p></li>
                <li><p>异构设备联动一体化</p></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="downList" @click="$router.push({name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}})"  data-aos="fade-up" data-aos-duration="1000">
        <div class="Mobile">
          <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}" class="topImg">
            <img :src="baseUrl+'imges/solution/downImg2.png'" alt="">
          </router-link>
        </div>
        <div class="solutionText">
          <div class="paddingBox">
            <p class="centerText">
              <img :src="baseUrl+'home/centerImg2.png'" alt="">
              <span class="centerTitle">智慧燃气管网</span>
              <span class="centerEnglish">Smart gas pipeline network</span>
            </p>
            <div class="downText">
              <span>智慧燃气系统是专门针对燃气行业输配关系，在实现燃气管线的集中管理，动态维护和应用服务基础上，做到管线信息...</span>
              <ul>
                <li><p>燃气管网压力、流量实时监测</p></li>
                <li><p>燃气输配管理“一张网”</p></li>
                <li><p>地下综合管网信息交换，共建共享</p></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="PC">
          <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}" class="topImg">
            <img :src="baseUrl+'imges/solution/downImg2.png'" alt="">
          </router-link>
        </div>
      </div>
      <div class="downList" @click="$router.push({name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}})"  data-aos="fade-up" data-aos-duration="1000">
        <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}" class="topImg">
          <img :src="baseUrl+'imges/solution/downImg3.png'" alt="">
        </router-link>
        <div class="solutionText">
          <div class="paddingBox">
            <p class="centerText">
              <img :src="baseUrl+'home/centerImg3.png'" alt="">
              <span class="centerTitle">智慧地下管网</span>
              <span class="centerEnglish">Intelligent pipe network</span>
            </p>
            <div class="downText">
              <span>地下管网系统可实现城市地下所有管线集中管理、动态维护和应用服务。系统结合二三维一体化、三维可视化、大数据...</span>
              <ul>
                <li><p>城市内涝预警，危害预测</p></li>
                <li><p>供水管网泄漏监测，应急响应</p></li>
                <li><p>地下管网施工模拟，管网分析</p></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Solution",
  data() {
    return {}
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    setTimeout(() =>{
      this.$refs.cur.classList.add('small')
      this.$refs.cur1.classList.add('small')

    }, 400)
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.Mobile{display: none}
.PC{display: block}
#solution {
  position: relative;
  background: #FFFFFF;
  width: 100%;
  min-width: 1250px;
  //顶部样式
  .topContent{
    position: relative;
    width: 100%;
    height: 1080px;
    .mainContent{
      cursor: default;
      user-select: none;
      overflow: hidden;
      .img_big{
        display: block;
        width: 100%;
        height: 1080px;
        transition: all 1.4s;
        transform: scale(1.06);
      }
      .MobileBG{
        display: none;
      }
      .small{
        transform: scale(1);
      }
      h4{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 436px;
        width: 708px;
        height: 43px;
        font-family: Medium;
        font-size: 50px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
      p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 868px;
        height: 42px;
        top: 372px;
        font-family: Regular;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0em;
        color: #F2F3FE;
        z-index: 10;
      }
      .contentBox{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 528px;
        width: 501px;
        height: 53px;
        background: rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-family: Regular;
        font-size: 14px;
        line-height: 53px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
    }
  }
  //中部样式
  .navContent{
    display: flex;
    justify-content: space-between;
    margin: 115px auto 150px;
    width: 1624px;
    height: 327px;
    .navList{
      width: 300px;
      height: 307px;
      p{
        font-family: Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #3D3D3D;
      }
      span{
        height: 42px;
        display: block;
        margin: 13px 7px 40px 12px;
        font-family: Regular;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0em;
        color: #454545;
      }
      img{
        width: 300px;
        height: 210px;
        transition: all .6s;
      }
      img:hover{
        transform: scale(1.1);
      }
    }
  }
  //底部样式
  .downContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 176px;
    width: 1600px;
    height: 1408px;
    .downList{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 380px;
      cursor: pointer;
      .PC{
        width: 860px;
        height: 380px;
        overflow: hidden;
      }
      a{
        width: 860px;
        height: 380px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: all .6s;
        }
        img:hover{
          transform: scale(1.1);
        }
      }
      .solutionText{
        width: 740px;
        height: 380px;
        background: #F7FAFC;
        .paddingBox{
          position: relative;
          width: 437px;
          margin-left: 85px;
          .centerText{
            width: 428px;
            height: 110px;
            border-bottom: 1px solid #D8D8D8;
            img{
              position: absolute;
              top: 49.38px;
              left: 0;
            }
            .centerTitle{
              position: absolute;
              left: 60px;
              top: 49.38px;
              width: 154px;
              height: 29px;
              font-family: Regular;
              font-size: 18px;
              font-weight: normal;
              text-align: left;
              letter-spacing: 0em;
              color: #3D3D3D;
            }
            .centerEnglish{
              position: absolute;
              left: 60px;
              top: 75px;
              height: 20px;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              text-transform: uppercase;
              letter-spacing: 0em;
              color: #999999;
            }
          }
          .downText{
            span{
              position: absolute;
              left: 0px;
              top: 126px;
              text-align: left;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              line-height: 31px;
              letter-spacing: 0em;
              color: #3D3D3D;
            }
            ul{
              position: absolute;
              left: 0;
              top: 216px;
              text-align: left;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              line-height: 37px;
              letter-spacing: 0em;
              color: #666666;
              li{
                background: url("http://www.cd-rst.top:18000/static/home/point.png") left no-repeat;
                p{
                  margin-left: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  .Mobile{display: block}
  .PC{display: none}
  #solution{
    position: relative;
    width: 100%;
    background-color: #fff;
    //第一屏幕区
    .topContent{
      position: relative;
      width: 100%;
      height: 590px;
      .mainContent{
        cursor: default;
        user-select: none;
        overflow: hidden;
        .img_big{
          display: none;
        }
        //p,h4,div{
        //  display: none;
        //}
        h4{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 205px;
          font-family: Medium;
          width: 100%;
          height: 43px;
          font-size: 30px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: #FFFFFF;
          z-index: 1;
        }
        p{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 169px;
          font-family: Regular;
          width: 196px;
          height: 17px;
          font-size: 14px;
          font-weight: normal;
          line-height: 17px;
          letter-spacing: 0em;
          color: #F2F3FE;
          z-index: 0
        }
        .contentBox{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 266px;
          background: rgba(255, 255, 255, 0);
          box-sizing: border-box;
          border: 1px solid rgba(255, 255, 255, 0);
          font-family: Regular;
          width: 228px;
          height: 36px;
          font-size: 12px;
          font-weight: normal;
          line-height: 18px;
          letter-spacing: 0em;
          color: #FFFFFF;
          z-index: 1
        }
        .MobileBG{
          display: block;
          width: 100%;
          height: 590px;
          transition: all 1.4s;
          transform: scale(1.06);
        }
        .small{
          transform: scale(1);
        }
      }
    }
    //第二屏幕区
    .navContent{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: left;
      //width: 280px;
      width: 79%;
      height: 100%;
      margin-top: 80px;
      .navList{
        width: 100%;
        //height: 301.7px;
        height: 84vw;
        margin-bottom: 80px;
        p{
          width: 100%;
          height: 22px;
          font-family: Medium;
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: #3D3D3D;
          margin-bottom: 13px;
        }
        span{
          width: 100%;
          height: 42px;
          font-family: Regular;
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0em;
          color: #454545;
          margin-bottom: 20px;
        }
        img{
          width: 100%;
          //height: 194.7px;
          height: 54vw;
        }
      }
    }
    //第三屏幕区
    .downContent{
      display: flex;
      flex-direction: column;
      justify-content: left;
      //width: 320px;
      width: 90%;
      height: 100%;
      .downList{
        width: 100%;
        height: 487px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-bottom: 50px;
        a{
          width: 100%;
          //height: 170px;
          height: 48vw;
          img{
            width: 100%;
            height: 100%;
            transition: all .6s;
          }
          img:hover{
            transform: scale(1.1);
          }
        }
        .solutionText{
          //width: 319.06px;
          width: 100%;
          height: 317px;
          background: #F7FAFC;
          .paddingBox{
            position: relative;
            width: 300.29px;
            margin-left: 18.77px;
            margin-top: 18px;
            .centerText{
              width: 100%;
              height: 69px;
              border-bottom: 1px solid #D8D8D8;
              text-align: left;
              img{
                position: absolute;
                //left: 11.19px;
                left: 0;
                top: 8.38px;
                width: 35.63px;
                height: 33.25px;
              }
              .centerTitle{
                position: absolute;
                //left: 62px;
                left: 50px;
                top: 0;
                width: 154px;
                height: 29px;
                font-family:Regular;
                font-size: 18px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #3D3D3D;
              }
              .centerEnglish{
                position: absolute;
                top: 29px;
                //left: 62px;
                left: 50px;
                width: 213px;
                height: 20px;
                font-family: Regular;
                font-size: 12px;
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 0em;
                color: #999999;
              }
            }
            .downText{
              span{
                position: absolute;
                left: 0;
                top: 80px;
                width: 281.52px;
                height: 192px;
                font-family: Regular;
                font-size: 13px;
                font-weight: normal;
                line-height: 26px;
                letter-spacing: 0em;
                color: #3D3D3D;
              }
              ul{
                position: absolute;
                left: 0px;
                top: 175px;
                width: 100%;
                height: 37px;
                font-family: Regular;
                font-size: 14px;
                font-weight: normal;
                line-height: 31px;
                letter-spacing: 0em;
                color: #666666;
                li{
                  font-family: Regular;
                  background: url("http://www.cd-rst.top:18000/static/home/point.png") left no-repeat;
                  p{
                    font-family: Regular;
                    margin-left: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
